import React from "react"
import PropTypes from "prop-types"
import { GatsbyImage } from "gatsby-plugin-image"

const ColumnItem = ({
  column: {
    data: { Url: url, Title: title, Image: image },
  },
}) => {
  return (
    <a
      href={url}
      target="_blank"
      rel="noreferrer noopener"
      className="cursor-pointer"
    >
      <div className="bg-white w-full h-20 sm:h-32 rounded-lg shadow-md duration-300 ease-out transform transition-all hover:shadow-xl hover:-translate-y-2">
        <div className="p-4 sm:p-8 h-full">
          <GatsbyImage
            alt={title}
            image={image.localFiles[0].childImageSharp.gatsbyImageData}
            objectFit="contain"
            className="h-full"
            loading="eager"
          />
        </div>
      </div>
    </a>
  )
}

const ColumnList = ({ columnList }) => {
  const columns = columnList.map((column, index) => (
    <ColumnItem key={index} column={column} />
  ))
  return <>{columns}</>
}

ColumnItem.propTypes = {
  column: PropTypes.object,
}
ColumnList.propTypes = {
  columnList: PropTypes.array,
}

export default ColumnList
