import React from "react"
import PropTypes from "prop-types"
import { FaExternalLinkAlt } from "react-icons/fa"
import { GatsbyImage } from "gatsby-plugin-image"

const MediaItem = ({
  media: {
    data: { Url: url, Date: date, Title: title, Source: source, Image: image },
  },
  isFirst,
}) => {
  return (
    <a
      href={url}
      target="_blank"
      rel="noopener noreferrer"
      className={`group ${
        isFirst
          ? "sm:col-span-2 lg:col-span-3 lg:grid lg:grid-cols-3 lg:gap-12 lg:h-full"
          : "col-span-1"
      }`}
    >
      <div className="lg:col-span-2 relative aspect-[16/9]">
        <GatsbyImage
          alt={title}
          image={image.localFiles[0].childImageSharp.gatsbyImageData}
          className="w-full h-full object-center object-cover"
        />
        <div className="absolute top-0 left-0 w-full h-full z-10 transition-all duration-300 ease-out opacity-0 flex items-center justify-center hover:opacity-100">
          <div className="absolute top-0 left-0 w-full h-full bg-black opacity-50" />
          <FaExternalLinkAlt className="text-6xl text-white relative z-20" />
        </div>
      </div>
      <div className={`${isFirst ? "lg:my-auto" : ""}`}>
        <p className="text-gray-500 mt-6">
          {date}．{source}
        </p>
        <p
          className={`text-gray-700 text-xl font-black font-serif tracking-wide mt-2 group-hover:text-forhover group-hover:underline group-hover:decoration-forhover ${
            isFirst ? "sm:text-3xl" : ""
          }`}
        >
          {title}
        </p>
        {/* 作者（暫時隱藏起來）*/}
        {/*<div className="flex items-center mt-2">*/}
        {/*  {people.Profile && (*/}
        {/*    <img*/}
        {/*      alt={people.Name}*/}
        {/*      src={people.Profile[0].url}*/}
        {/*      width={people.Profile[0].width}*/}
        {/*      height={people.Profile[0].height}*/}
        {/*      className="rounded-full w-8 h-8 object-cover mr-3"*/}
        {/*    />*/}
        {/*  )}*/}
        {/*  <p className="text-gray-500 font-bold">{people.Name}</p>*/}
        {/*</div>*/}
      </div>
    </a>
  )
}

const MediaList = ({ mediaList }) => {
  const medias = mediaList.map((media, index) => (
    <MediaItem key={index} media={media} isFirst={index === 0} />
  ))
  return <>{medias}</>
}

MediaItem.propTypes = {
  media: PropTypes.object,
  isFirst: PropTypes.bool,
}
MediaList.propTypes = {
  mediaList: PropTypes.array,
}

export default MediaList
