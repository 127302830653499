import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { GatsbySeo } from "gatsby-plugin-next-seo"
import useMetadata from "../hooks/useMetadata"
import useLandingImage from "../hooks/useLandingImage"
import Layout from "../components/Layout"
import ColumnList from "../components/media/ColumnList"
import MediaList from "../components/media/MediaList"

const Media = ({
  data: {
    Media: { nodes: mediaList },
    Column: { nodes: columnList },
  },
}) => {
  const { media: mediaImage, mediaOGImage } = useLandingImage()
  const { siteUrl } = useMetadata()
  const metaTitle = "媒體報導"
  const metaDescription = "喆律法律事務所媒體報導及合作專欄"
  const ogImage = `https:${mediaOGImage}`
  const ogImageAlt = metaTitle
  const canonical = `${siteUrl}/media`

  return (
    <Layout transParentMenu={false}>
      <GatsbySeo
        title={metaTitle}
        description={metaDescription}
        canonical={canonical}
        openGraph={{
          title: metaTitle,
          description: metaDescription,
          url: canonical,
          images: [
            {
              url: ogImage,
              alt: ogImageAlt,
            },
          ],
          type: "website",
        }}
      />

      <div className="mt-nav">
        <div className="relative w-full">
          <div className="absolute inset-0">
            <div className="absolute bg-black w-full h-full opacity-50 z-20" />
            <GatsbyImage
              className="w-full h-full z-10"
              image={mediaImage}
              alt="其他同仁"
              objectFit="cover"
              loading="eager"
              imgStyle={{
                objectPosition: "center",
              }}
            />
          </div>

          <div className="relative z-30 text-center h-full px-6 sm:px-8 py-16 lg:py-24">
            <div className="container">
              <div className="text-white max-w-4xl text-3xl sm:text-4xl font-black join-title title-border">
                合作專欄
              </div>
              <div className="grid gap-6 sm:gap-12 grid-cols-2 lg:grid-cols-4 mt-6 sm:mt-12">
                <ColumnList columnList={columnList} />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container py-16 lg:py-24">
        <div className="flex justify-center">
          <h2 className="mx-auto text-black max-w-4xl text-3xl sm:text-4xl font-black join-title title-border">
            媒體報導
          </h2>
        </div>
        <div className="grid gap-12 sm:grid-cols-2 lg:grid-cols-3 mt-6 sm:mt-12">
          <MediaList mediaList={mediaList} />
        </div>
      </div>
    </Layout>
  )
}

Media.propTypes = {
  data: PropTypes.object,
}

export default Media

export const pageQuery = graphql`
  query {
    Media: allAirtableMedia(sort: { fields: data___Date, order: DESC }) {
      nodes {
        data {
          Url
          Title
          Source
          Image {
            localFiles {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED)
              }
            }
          }
          Date(formatString: "YYYY-MM-DD")
        }
      }
    }
    Column: allAirtableColumn {
      nodes {
        data {
          Source
          Title
          Url
          Image {
            localFiles {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED)
              }
            }
          }
        }
      }
    }
  }
`
